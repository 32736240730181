.event-table{
    font-size:12px;
}

.event-badge{
    padding:5px;
    border-radius: 5px;
    background-color: green;
    text-align: center;
    color:white;
}