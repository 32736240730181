:root {
    --border-color: rgb(223, 223, 223);
    --light-gray-input: rgb(238, 238, 238);
    --light-dark-gray-input: rgb(223, 223, 223);
}

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.8rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}



.message-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.message-user-wrapper {
    background-color: rgb(255, 255, 255);
    width: 100%;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    flex: 2;
    height: 80vh;

}

.message-user-wrapper .user-panel-header {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid var(--border-color);
    font-weight: bold;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.message-user-wrapper .user-panel-body {}

.message-user-wrapper .message-search {
    width: 100%;
    padding: 5px;

}

.message-user-wrapper .message-search>input {
    border: 0px;
    background-color: var(--light-gray-input);
    padding: 10px;
    padding-left: 35px;
    border-radius: 5px;
    width: 99%;
}

.message-user-wrapper .message-search>input::placeholder {

    color: rgb(128, 128, 128);
    font-size: 14px;
    font-weight: 400;

}

.message-user-wrapper .message-search>input::selection,
input:focus,
input:focus-visible {
    outline: none;
    outline-offset: 0px !important;

}

.message-user-wrapper .user-panel-header .user-panel-header-title {
    flex: 9;
    font-weight: 600;
    font-size: 18px;
}

.message-user-wrapper .user-panel-header .user-panel-header-tools {
    flex: 1;
    text-align: right;
    font-size: 18px;
}


.user-panel-body .user-list {
    width: 100%;
    /* padding: 5px; */
    margin-top: 10px;
}

.user-panel-body .user-list .user-item {
    width: 100%;
    padding: 10px 5px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    border-left: 4px solid white;
    transition: all 0.2s;
    cursor: pointer;
}

.user-panel-body .user-list .user-item:hover {
    background-color: rgb(231, 231, 231);
    border-left-color: rgb(231, 231, 231);
}

.user-panel-body .user-list .user-item img {
    width: 50px;
    height: 50px;
    border: 2px solid #bcad96;
}

.user-panel-body .user-list .user-item .user-item-name {
    width: 100%;
    margin-left: 5px;
}

.user-panel-body .user-list .user-item .user-item-name>span {
    font-weight: 600;
    font-size: 15px;
}

.user-panel-body .user-list .user-item .user-item-name>div {
    font-size: 13px;
}

.user-panel-body .user-list .user-item .user-item-date {
    font-size: 11px;
    width: 55px;
}


.user-panel-body .user-list .user-item.active {
    background-color: var(--light-gray-input);
    border-left-color: #bcad96;
}

.user-panel-body .user-list .user-item.unread {
    background-color: var(--light-gray-input);

    border-left-color: var(--light-dark-gray-input);
    font-weight: bold;
}



.message-content-wrapper {
    width: 100%;
    flex: 5;
    background-color: white;
    border: 1px solid var(--border-color);
    border-left: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-right: 5px;
}

.message-content-wrapper .message-title {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;
}

.message-content-wrapper .message-title .message-user-name {
    width: 100%;
}

.message-content-wrapper .message-title .message-user-name>span {
    font-size: 14px;
    font-weight: bold;
}

.message-content-wrapper .message-title .message-user-name>.message-user-title {
    font-size: 12px;
}

.message-content-wrapper .message-title .message-tools {
    text-align: right;
    width: 100px;
}

.message-content-wrapper .messages {
    width: 100%;
    height: 45vh;
    overflow-y: auto;
    border-bottom: 1px solid var(--border-color);

}

.message-content-wrapper .messages .message-item {
    width: 100%;
    transition: all 0.2s;
    display: flex;
    padding: 10px;
}

.message-content-wrapper .messages .message-item:hover {
    background-color: rgb(240, 240, 240);
}

.message-content-wrapper .messages .message-item img {
    width: 50px;
    height: 50px;
    border: 2px solid #bcad96;
}

.message-content-wrapper .messages .message-item .message-content {
    width: 100%;
    margin-left: 5px;
}

.message-content-wrapper .messages .message-item .message-content .message-item-header {
    display: flex;
}

.message-content-wrapper .messages .message-item .message-content .message-item-header .message-item-header-name {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
}

.message-content-wrapper .messages .message-item .message-content .message-item-header .message-item-header-name>span {
    font-size: 12px;
    font-weight: 500;
}

.message-content-wrapper .messages .message-item .message-text {
    font-size: 14px;
    width: 100%;
}

.message-content-wrapper .new-message {
    text-align: right;
    width: 100%;
    padding: 10px;

}

.message-content-wrapper .new-message>textarea {
    background-color: var(--light-gray-input);
    border: 0px;
    border-radius: 10px;
}

.message-right-panel {
    width: 100%;
    flex: 2;
}

.search-result-list {
    width: 100%;
}

.search-user-item {
    width: 100%;
    padding: 10px 5px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    border-left: 4px solid white;
    transition: all 0.2s;
    cursor: pointer;
    border-bottom: 1px solid rgb(238, 238, 238);
}
.search-user-item:hover {
    background-color: rgb(240, 240, 240);
}
.search-user-name {
    display: flex;
    width: 100%;
    margin-left: 5px;
}
 #dropdown-basic::after {
    display: none;
  
  }