.eventItem{
    display: flex;
    width:100%;
    align-items: center;

}
.welcomeModal{
    width: 50vw ;
    max-width: 50vw;
}
 