body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.business-card-container {

  margin-bottom: 20px;
  border: 1px solid #D9D9D9;
  background-color: white;
  padding: 10px;
  width: 100%;
}

.business-card-item-wrapper {
  color: rgb(54, 54, 54);
  padding: 10px 5px;
  border: 1px solid rgb(236, 236, 236);
  border-radius: 5px;
  background-color: rgba(250, 250, 250, 0.308);
  margin-bottom: 15px;
  width: 100%;
}


.business-card-item {
  width: 100%;
  display: flex;
}

.business-card-item-holder {
  line-height: 20px;
  /* margin-left: 5px; */
  font-size: 13.5px;
  flex: 8;
  margin: auto 5px;
}

.business-card-card {
  margin: auto;
  flex: 2;
}

.business-card-item-image {}

.business-card-item-btn {}

.post-comment-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;


  margin-bottom: 5px;

}

.post-comment-item .comment-text {
  padding: 5px 5px;
  /* line-height: 30px; */
  border: 1px solid rgb(228, 228, 228);
  border-radius: 3px;
  background-color: #f5f5f5;
  font-size: 14px;
  width: 100%;
}

.post-comment-item .post-comment-image {
  padding: 5px;
  margin-right: 10px;

}

.post-comment-item .user-profile {


  margin-right: 10px;
  font-weight: bold;
  font-size: 14px;
  width: 100%;


}

.post-comment-item .user-title {

  color: rgb(173, 173, 173) !important;
  margin-right: 10px;
  font-size: 12px;
  font-weight: normal;

  margin-bottom: 5px;
}

.post-comment-item .comment-header {
  display: flex;
  /* align-items: cen; */
  width: 100%;
  border-bottom: 1px solid rgb(230, 230, 230);

}

.post-comment-create-wrapper {
  width: 100%;
  margin-bottom: 5px;

}

.post-comment-create-wrapper>textarea {
  width: 100%;

}

.comment-text>textarea {
  background-color: #f5f5f5;
  border: 0px;
  width: 100%;
}

.comment-date {
  font-size: 10px;
  color: rgb(173, 173, 173) !important;
  margin-right: 10px;
  margin-top: 5px;

}

.like-success {
  font-weight: bold;
  color: #3d97bb;
}

.like-success:hover {
  font-weight: bold;
  color: #2a83a7 !important;
}

.view-card-button {
  display: inline-block;
  background-color: #BAAB94;
  border-radius: 30px;
  padding: 5px 17px;
  color: #fff;
  font-weight: 500;
  margin: 20px auto 10px;
  font-size: 13px;
  margin-top: 2px;
  transition: all 0.5s;
}

.view-card-button:hover {
  background-color: #16202C;
  color: #fff;

}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  /* border: 1px solid #ccc;q */
  display: inline-block;
  /* padding: 6px 12px; */
  cursor: pointer;
}

.pending-request-item {
  display: flex;
  border-bottom: 1px solid rgb(224, 224, 224);
  padding: 5px;
  padding-bottom: 10px;
  padding-top: 10px;
  /* margin-bottom: 10px; */
  border-radius: 5px;


  /* margin-bottom: 10px; */

}

.pending-request-item-image {
  margin: auto;

}

.pending-request-item-content {
  margin: auto;
  margin-left: 15px;
  flex: 8;


}

.pending-request-item-name {
  font-size: 14px;
  font-weight: bold;

}

.pending-request-item-subtitle {
  font-size: 13px;

}

.pending-request-item .actions {
  width: 70px;
  flex: 2;

}

.pending-request-item .actions .btn {
  font-size: 12px;
  padding: 3px;
  margin-top: 1px;
}

.post-preview-wrapper {
  width: 99%;
  border: 1px solid #eef3f8;
  border-radius: 5px;
  margin: 15px auto;
  position: relative;


}

.post-preview-image {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.post-preview-image img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.post-preview-description {
  padding: 5px;

  background-color: #eef3f8;

}

.post-preview-title {
  font-weight: 600;
  font-size: 15px;
  color: #2c2c2c;
  background-color: #eef3f8;
  margin-top: 5px;
  margin-bottom: 5px;

}

.post-pdf-wrapper {
  padding: 5px;
  border: 1px solid rgb(221, 221, 221);
  background-color: rgb(248, 248, 248);
  border-radius: 5px;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s;
  cursor: pointer;
}

.post-pdf-wrapper:hover {
  border: 1px solid rgb(185, 185, 185);
  background-color: rgb(241, 241, 241);

}


.post-pdf-title {
  font-size: 14px;
  font-weight: 500;
}

.post-pdf-size {
  font-size: 12px;
  color: #acacac
}


.post-preview-remove-button {
  position: absolute;
  top: 10px;
  right: 15px;
  /* height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: rgb(0, 0, 0); */

  /* padding-top:0.35rem ; */
  /* text-align: center; */
}

.post-preview-remove-button i {
  font-size: 30px;
  color: rgb(44, 44, 44);
  transition: all 0.2s;
  cursor: pointer;
  opacity: 0.8;
}

.post-preview-remove-button i:hover {
  opacity: 1;
}



.post-preview-url {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  color: #555555;

}

.post-link {
  color: #00307b;
  font-weight: 600;
}

.profile-picture-edit-wrapper {
  position: absolute;

}

.profile-update-preview {
  width: 100%;
  height: 200px;
}

.post-type-selection-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 30px 0px;
}

.post-type-selection-item {
  padding: 15px 5px;
  border: 1px solid #d6d6d6;
  background-color: rgb(236, 236, 236);
  border-radius: 5px;
  color: rgb(36, 36, 36);
  font-size: 14px;
  width: 90%;
  margin-right: 5px;
  text-align: center;
  cursor: pointer;

}

.post-type-selection-item.selected {

  border: 1px solid #d6d6d6;
  background-color: rgb(19, 86, 141);
  color: white;


}

.post #dropdown-basic::after {
  display: none;

}

.post-filter-wrapper {
  width: 100%;
  text-align: right;
  border-bottom: 1px solid rgb(199, 199, 199);


}

.post-filter-wrapper span button {

  font-size: 13px !important;
}

.page-loader-wrapper {
  width: 100%;
  left: 0;
  background-color: #f0f2f5;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 99999999;
}

.page-loader {
  width: 30%;
  padding: 20px;
  left: 50%;
  margin-left: -15%;
  background-color: white;
  border-radius: 5px;
  position: absolute;
  top: 200px;
  text-align: center;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem transparent;
}

.profile-biography {
  font-size: 0.9rem;
}

.new-post-input {
  border: 0px !important;
  width: 100%;
  border: 0;
  outline: 0;
  resize: none;
  background: transparent;
  min-height: 100px;

}

.new-post-input:active {
  border: 0px !important;


}

.create-post-modal-body {
  max-height: 60vh;
  overflow-y: auto;
}

.create-post-modal .left-footer {
  display: flex;
  align-items: inherit;
}

.create-post-modal .left-footer .share-section.disabled {
  opacity: 0.6;
  pointer-events: none;
  cursor: no-drop;
}


.left-footer .share-section {
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px;
  /* border-radius: 50%; */
  text-align: center;
}

.left-footer .share-section:hover {
  background-color: rgb(237, 237, 237);
  border-radius: 5px;
}

.left-footer .celebration-selection {
  margin-right: 5px;
  padding: 5px 10px;
  background-color: #efefef;
  border-radius: 10px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s;
  ;
}

.left-footer .celebration-selection:hover {
  background-color: #d2d2d2;
}

.create-post-modal .modal-footer {
  justify-content: space-between;
}

.create-post-modal .pdf-file-wrapper {
  display: flex;
  font-weight: 500;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  /* align-content: center; */
  background-color: #f8f8f8;
  border-radius: 0;
}

.create-post-modal .pdf-file-wrapper .remove-button {
  transition: all 0.2s;
  cursor: pointer;


}

.create-post-modal .pdf-file-wrapper .remove-button:hover {
  color: #8f0404;

}

.modal-lg {
  max-width: 550px;
}

.image-area {
  position: relative;
}

.create-post-network-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.create-post-network-wrapper .user-name {
  font-weight: 500;
  padding: 3px 10px;

  border: 1px solid rgb(239, 239, 239);
  border-radius: 30px;
  margin-left: 10px;
  font-size: 14px;

}

.create-post-network-wrapper .post-scope {
  font-weight: 500;
  padding: 3px 10px;

  border: 1px solid rgb(239, 239, 239);
  border-radius: 30px;
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
}

.create-post-network-wrapper .post-scope:hover {
  background-color: rgb(246, 246, 246);
  border: 1px solid rgb(215, 215, 215);

}

.post-state-selection-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.post-state-selection-item {
  width: 100%;
  background-color: rgb(236, 236, 236);
  border: 1px solid #d6d6d6;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  /* text-align: center; */
  font-weight: 500;
  transition: all 0.2s;
}

.post-state-selection-item:hover {
  background-color: rgb(212, 212, 212);

}

.post-state-selection-item.selected {
  border: 1px solid #d6d6d6;
  background-color: rgb(19, 86, 141);
  color: white;


}

.profile-flag {
  width: 32px;
  position: absolute;
  top: 290px;
}

.list-user-item {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid rgb(229, 229, 229);
  padding-bottom: 5px;
  padding-top: 5px;
  cursor: pointer;
}

.list-user-name-wrapper {
  width: 100%;
  margin-left: 5px;
}

.list-user-name {
  font-weight: bold;
  width: 100%;
  font-size: 13px;
}

.list-user-title {
  width: 100%;
  font-size: 12px;
}

.notification-link {}

.notification-count {
  background-color: rgb(128 155 184);
  /* padding:3px 6px; */
  border-radius: 50%;
  color: white;
  /* font-size:11px; */
  position: absolute;
  top: 0;
  right: 25px;
  width: 10px;
  height: 10px;
}

.message-count {
  background-color: rgb(128 155 184);
  /* padding:3px 6px; */
  border-radius: 50%;
  color: white;
  /* font-size:11px; */
  position: absolute;
  top: 0;
  right: 14px;
  width: 10px;
  height: 10px;
}

.events-button {
  /* position: fixed; */
  /* bottom: 15px;
  right: 15px; */
  padding: 15px 20px;
  background-color: rgb(128 155 184);
  border: 1px solid rgb(128 155 184);
  color: white;
  /* border-radius: 5px; */
  cursor: pointer;

}

@keyframes blinker {
  50% {
    opacity: 0.7;
  }
}

.blink_me {
  animation: blinker 2s linear infinite;
}

.profile-description {
  display: flex;
  align-items: center;
}

.country-group-item {
  display: flex;
  width: 100%;
  padding: 10px 5px;
  border-bottom: 1px solid rgb(211, 211, 211);
  flex-direction: row;
  align-items: center;
  transition: all 0.2s;
}

.country-group-item span {
  font-weight: 500;
  font-size: 14px
}

.country-group-item .credit {
  width: 100px;
  margin-right: 10px;
  font-size: 13px;
}

.country-group-item:hover {
  background-color: rgb(240, 240, 240);
  /* border-bottom: 1px solid white;
  border-radius: 5px; */
}
.country-group-item .join-link{
  background-color: #BAAB94;
  color:white;
  width: 90px;
}