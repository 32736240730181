.filter-type-wrapper {
    font-weight: 500;
    padding: 10px 15px;
    border: 1px solid rgb(239, 239, 239);
    border-radius: 30px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s;
    background-color: white;
    width: 100px;
    margin-bottom: 10px;
  }

  .filter-card-list{
    width: 100%;

  }
  .filter-card-item{
    width: 100%;
    display: flex;
    /* border:1px solid red; */
    background-color: rgb(241, 241, 241);
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
    align-items:center ;
    cursor:pointer;
    transition: 0.2s all;
  }

  .filter-card-item:hover{
    width: 100%;
    display: flex;
    /* border:1px solid red; */
    background-color: rgb(228, 228, 228);
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
    align-items:center ;
    cursor:pointer;
  }
  .filter-car-item-name{
    margin-left: 5px;
  }