:root {
    --border-color: rgb(223, 223, 223);
    --light-gray-input: rgb(238, 238, 238);
    --light-dark-gray-input: rgb(223, 223, 223);
}
.notification-wrapper{
    width: 100%;
    border:1px solid var(--border-color);
    background-color:white;
    /* padding:10px; */
    border-radius: 5px;
    min-height: 300px;
}
.notification-item{
    width: 100%;
    padding:10px 5px;
    display: flex;
    align-items: center;
    cursor:pointer;
}
.notification-item:hover{
    background-color:var(--light-gray-input);
}

.notification-item.new{
    background-color:#dbe8f6;
}
.notification-item.new:hover{
    background-color:#d1e1f3;
}

.notification-item > img{
   border: 2px solid #BAAB94;
}
.notification-item .notification-text
{
    margin-left: 5px;
    width: 100%;
    font-size:13px;
}
.notification-item .notification-text .user
{
font-weight: 600;
}
.notification-time{
    font-size:12px;
    width: 120px;
    text-align: right;
    color:rgb(139, 139, 139);
}
